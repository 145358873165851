import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Seo from 'components/seo';
import Footer from 'components/Footer/Footer';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import { ArticleH2 } from 'components/common/Styles';

interface IPopularLocation {
	name: string;
	slug: string;
	postcode: string;
	state: { text: string };
}

interface IPopularLocations {
	pageContext: {
		dataPopularLocations: IPopularLocation[];
	};
}

const PopularClinics: React.FC<IPopularLocations> = ({ pageContext: { dataPopularLocations } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo title='Popular physiotherapy suburbs in Australia' description='' />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<Wrap>
						<TopClinicsWrapper>
							<TopClinicsHeader>
								<ArticleH2>Popular Physiotherapy Suburbs in Australia</ArticleH2>
							</TopClinicsHeader>
							<ListWrap>
								{dataPopularLocations.map((location) => (
									<Item key={location.slug}>
										<Link to={location.slug}>
											{location.name} {location.state.text} {location.postcode}
										</Link>
									</Item>
								))}
							</ListWrap>
						</TopClinicsWrapper>
					</Wrap>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const TopClinicsWrapper = styled.div`
	padding: 0 10px;
`;

const Wrap = styled.div`
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
`;

const TopClinicsHeader = styled.div`
	margin: 80px auto 0;
	border-bottom: 1px solid #e0e0df;
	padding-bottom: 50px;
	@media (max-width: 768px) {
		margin: 40px auto 0;
		padding-bottom: 40px;
		text-align: left;
	}
`;
const ListWrap = styled.ul`
	list-style: none;
	margin-top: 50px;
	padding-bottom: 50px;

	& a {
		display: inline-block;
		transition: all 0.3s ease;
		margin-bottom: 18px;
		font-size: 18px;
		font-weight: 400;
		color: #424bec;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
	@media (max-width: 768px) {
		margin-top: 30px;

		& a {
			font-size: 16px;
		}
	}
`;

const Item = styled.div``;

export default PopularClinics;
